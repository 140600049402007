import { useMutation, useQuery } from '@apollo/client';
import Layout from '@layouts';
import { getErrorMessages, getInvalidFields, SecurityContext } from '@lib';
import { useFormik } from 'formik';
import { PageProps } from 'gatsby';
import * as React from 'react';
import { FC, useContext, useEffect } from 'react';
import { toast } from 'react-hot-toast';

import {
  GetUserOnNotificationPreferencesPageDocument,
  UpdateNotificationPreferencesDocument,
} from '@redsleeve/oilynx-domain';

import Button from '@components/Button';
import CheckboxInput from '@components/form/CheckboxInput';

import {
  hasNotificationPermission,
  pushSupported,
  urlBase64ToUint8Array,
} from '@lib/push';

import Menu from './Menu';

const NotificationsPage: FC<PageProps> = ({ location }) => {
  const { webPushPublicKey } = useContext(SecurityContext);

  const notificationPreferencesQuery = useQuery(
    GetUserOnNotificationPreferencesPageDocument
  );
  const [updateNotificationPreferences] = useMutation(
    UpdateNotificationPreferencesDocument
  );

  useEffect(() => {
    //always update sw
    if (pushSupported() && 'serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((swRegistration) =>
        swRegistration.update()
      );
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      push: false,
      email: false,
      sms: false,
    },
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      setSubmitting(true);
      const toastId = toast.loading('Updating notification settings...');
      try {
        let push: PushSubscription | undefined = undefined;
        if (
          pushSupported() &&
          (await navigator.serviceWorker.getRegistrations()).length
        ) {
          if (!values.push) {
            try {
              const swRegistration = await navigator.serviceWorker.ready;
              const subscription =
                await swRegistration.pushManager.getSubscription();
              await subscription.unsubscribe();
            } catch (ex) {
              toast.error('Failed to unsubscribe from push notifications.');
              console.warn(
                'Failed to unsubscribe from push notifications.',
                ex
              );
            }
          } else {
            const hasPermission = await hasNotificationPermission();
            if ('serviceWorker' in navigator && hasPermission) {
              try {
                const swRegistration = await navigator.serviceWorker.ready;

                push = await swRegistration.pushManager.subscribe({
                  userVisibleOnly: true,
                  applicationServerKey: urlBase64ToUint8Array(webPushPublicKey),
                });
              } catch (ex) {
                toast.error('Failed to subscribe to push notifications.');
                console.warn('Failed to subscribe to push notifications.', ex);
              }
            }
          }
        }

        await updateNotificationPreferences({
          variables: {
            preferences: {
              push: push?.toJSON(),
              email: values.email
                ? notificationPreferencesQuery.data?.currentUser.email
                : undefined,
              sms: values.sms
                ? notificationPreferencesQuery.data?.currentUser.phoneNumber
                : undefined,
            },
          },
        });

        toast.success('Notification settings updated!', { id: toastId });
      } catch (ex) {
        const formError = getInvalidFields(ex);
        if (formError) setErrors(formError);
        const errorMessages = getErrorMessages(ex);
        toast.error(
          errorMessages?.join('\n') ?? 'Failed to update notification settings',
          {
            id: toastId,
            duration: 10 * 1000,
          }
        );
        if (!errorMessages) console.error('[NotificationsPage] onSubmit', ex);
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (!notificationPreferencesQuery.data) return;
    const notificationPreferences =
      notificationPreferencesQuery.data.currentUser.notificationPreferences;
    formik.setValues({
      push: !!notificationPreferences.push,
      email: !!notificationPreferences.email,
      sms: !!notificationPreferences.sms,
    });
  }, [notificationPreferencesQuery.data]);

  return (
    <Layout
      url="/profile/notifications/"
      title="Notifications settings"
      disableIndex
    >
      <Menu currentPath={location.pathname}>
        <form
          onSubmit={formik.handleSubmit}
          className="flex-1 flex flex-col mb-0"
        >
          <div className="lg:pl-12 flex-1 flex flex-col">
            <h3 className="font-emp text-xl lg:text-2xl">
              Interest Notifications
            </h3>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-16 gap-y-4">
              <p className="col-span-1 col-start-1 text-sm opacity-60">
                Choose the type of notification you want to receive from us
              </p>
              <CheckboxInput
                formik={formik}
                name="push"
                label="Push notifications"
                className="col-span-1 col-start-1 p-1 lg:pl-4 m-0"
              />
              <CheckboxInput
                formik={formik}
                name="email"
                label="Email notifications"
                className="col-span-1 col-start-1 p-1 lg:pl-4 m-0"
              />
              <CheckboxInput
                formik={formik}
                name="sms"
                label="SMS notifications"
                className="col-span-1 col-start-1 p-1 lg:pl-4 m-0"
              />
            </div>

            <hr className="mt-12 mb-6 bg-white opacity-10" />

            <h3 className="font-emp text-xl lg:text-2xl mt-0">Newsletters</h3>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-16 gap-y-4">
              <pre className="col-span-1 col-start-1 p-1 pl-4 m-0">
                TODO monthly newsletter{' '}
              </pre>
            </div>

            <div className="flex-1" />
            <div className="p-4 -mx-4 mt-8 lg:mt-20 lg:bg-transparent">
              <Button
                className="w-full"
                type="submit"
                variant="primary"
                disabled={formik.isSubmitting}
              >
                Save Notification Settings
              </Button>
            </div>
          </div>
        </form>
      </Menu>
    </Layout>
  );
};

export default NotificationsPage;
